<script setup>
import { sendCode, signInEmail, signInEmailAndCode, signIn, changeInfo, changeByEmail } from '@/api/auth/auth';
import { defineType } from '@/utils/validation-utils';
import { getUser } from '@/utils/auth-header';
import { reactive, ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { changePasswordData } from '@/composables/changePassword';
import Alert from '@/components/popup/Alert';

const field = reactive({
  login: '',
  password: '',
  verificationCode: ''
});

const {
  setEmail,
  setUid
} = changePasswordData();

const password = ref(null);
const alert = ref(null);
const showCodeInput = ref(false);
const isLoggingIn = ref(false);
const error = ref(false);
const router = useRouter();

const buttonText = computed(() => (isLoggingIn.value) ? 'Авторизация...' : 'Войти');

function validateForm () {
  let type = defineType(field.login);

  if (type === 'email') {
    getToken();
  } else {
    showError('Введён некорректный e-mail', true);
  }
}

async function getToken() {
  isLoggingIn.value = true;

  let result = await signInEmail(field);
  if (result.success) {
    let auth = await signIn(result.data.token);

    if (auth === 200) {
      await router.push("/");
    } else {
      await showError('Возникла ошибка, обратитесь в службу поддержки');
    }
  } else {
    if (result.errorCode == 'INCORRECT_PASSWORD') {
      await showError(result.errorDescription);
    } else {
      error.value = true;
      result = await sendCode(field);
      showCodeInput.value = true;
    }
  }

  isLoggingIn.value = false;
}

const resetPasswordClicked = ref(false);

async function resetPassword() {
  if (resetPasswordClicked.value)
    return;

  if (!field.login) {
    showError('Пожалуйста, заполните поле с логином и повторите попытку восстановления доступа');
    return;
  }

  resetPasswordClicked.value = true;

  let result = await changeByEmail(field.login);
  if (result.success) {
    setEmail(field.login);
    setUid(result.data.uid);
    router.push('/login/confirm-email');
  } else {
    showError('Возникла ошибка при попытке восстановления доступа. Пожалуйста, попробуйте позже');
  }
  
  resetPasswordClicked.value = false;
}

async function acceptVerificationCode() {
  isLoggingIn.value = true;
  
  let result = await signInEmailAndCode(field);
  if (!result.success) {
    await showError(result.errorDescription);
  } else {
    await changeInfo(result.data.uid, result.data.token, {password: field.password});
    let auth = await signIn(result.data.token);
    if (auth === 200) {
      await router.push("/");
    }
  }
  isLoggingIn.value = false;
}

async function showError (text) {
  let output = text;
  if (!output)
    output = 'Возникла ошибка. Пожалуйста, попробуйте позже.';

  await alert.value.show(output, true);
}

onMounted(async () => {
    let user = await getUser();
    if (!user) {
      await router.push("/login");
    }
});
</script>

<template>
  <Alert ref="alert"/>
  <div class="auth d-flex flex-column justify-content-between">
    <label 
      class="auth__enter-text text-center my-4" 
      type="text">
      Вход
    </label>
    <div v-if="!showCodeInput"
      class="auth__login d-flex flex-column justify-content-around h-100">
      <div class="form-floating">
        <input 
          class="form-control" 
          id="emailAddress" 
          v-model="field.login" 
          type="email"
          @keyup.enter="password.focus()" 
          placeholder="Логин" 
          autofocus/>
        <label for="emailAddress">
          Логин
        </label>
      </div>
      <div class="form-floating">
        <input 
          class="form-control" 
          id="password" 
          ref="password" 
          v-model="field.password" 
          type="password" 
          @keyup.enter="validateForm" 
          placeholder="Пароль" 
          />
        <label for="password">
          Пароль
        </label>
      </div>
      <div class="mt-4 text-center">
        <span v-if="resetPasswordClicked" 
          class="spinner-grow spinner-grow-sm text-blue" 
          role="status" 
          aria-hidden="true"/>
        <a 
          href="#" 
          @click="resetPassword" 
          class="link-primary text-decoration-none">
          Забыли пароль?
        </a>
      </div>
      <div class="d-grid">
        <button 
          class="btn btn-primary btn-lg" 
          type="button" 
          @click="validateForm">
          <span v-if="isLoggingIn" 
            class="spinner-grow spinner-grow-sm" 
            role="status" 
            aria-hidden="true"/>
          {{ buttonText }}
        </button>
      </div>
    </div>
    <div v-if="showCodeInput">
      <div class="form-floating mx-5 mb-4">
        <input 
          class="form-control" 
          id="verificationCode" 
          v-model="field.verificationCode" 
          type="tel"
          placeholder="Код подтверждения"
          autofocus/>
        <label for="verificationCode">
          Код подтверждения
        </label>
        <div class="d-grid mx-5 my-4">
          <button 
            class="btn btn-primary btn-lg" 
            type="button" 
            @click="acceptVerificationCode">
            <span v-if="isLoggingIn" 
              class="spinner-grow spinner-grow-sm" 
              role="status" 
              aria-hidden="true"/>
            {{ buttonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.auth {
  width: 100%;
  height: 324px;
}

@media screen and (min-width: 600px) {
  .auth {
    height: 348px;
  }
}
.auth__enter-text {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.24px;
  font-feature-settings: 'tnum' on, 'lnum' on;

  color: #008BEA;
}

</style>
